import { render, staticRenderFns } from "./AddCourse.vue?vue&type=template&id=baddf1ac&scoped=true&"
import script from "./AddCourse.vue?vue&type=script&lang=js&"
export * from "./AddCourse.vue?vue&type=script&lang=js&"
import style0 from "./AddCourse.vue?vue&type=style&index=0&id=baddf1ac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baddf1ac",
  null
  
)

export default component.exports